
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditGradeModal from "@/components/modals/forms/EditGradeModal.vue";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "grade-details",
  components: {
   EditGradeModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewCategories {
      grade_id,
      grade_name,
      standard_no,
      active : {
        label :string;
        color :string;
      }
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      grade_id: "",
      grade_name: "",
      standard_no: "",
      active: {
        label: "",
        color: "",
      }
    }

    const getGradeDetails = async () => {
     
      try {

      var values = { "grade_id"  :  props.id, "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_GET_GRADE_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
     
        resultsView.value = {
          grade_id :  data.grade_id,
          grade_name : data.grade_name,
          standard_no: data.standard_no,
          active: {
            label: active_label,
            color: active_color
          },
        }
      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    
    onMounted( async () => {
      await getGradeDetails();
      setCurrentPageBreadcrumbs("Product Grade Details", ["Product Grade"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
